export enum TeamRoutePath {
    DASHBOARD = 'dashboard',
    PROJECTS = 'projects',
    REPORTS = 'reports',
    IMPROVEMENT_BANK = 'improvement-bank',
    SETTINGS = 'settings',
    COMPARE = 'compare',
    HELP_CENTER = 'help-center',
    ACCOUNT = 'account',
}
